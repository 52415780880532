import React from "react"

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="41.59"
      viewBox="0 0 184 41.59"
    >
      <path
        fill="#0a8543"
        d="M17.392 0A17.392 17.392 0 000 16.636V41.59s5.044 0 5.044-4.288v-7.668A17.392 17.392 0 1017.392 0zm0 29.74A12.348 12.348 0 1129.74 17.392 12.348 12.348 0 0117.392 29.74zM92.004 0a17.392 17.392 0 00-17.392 16.636V41.59s5.036 0 5.036-4.288v-7.668A17.392 17.392 0 1092.004 0zm0 29.74a12.348 12.348 0 1112.348-12.348A12.348 12.348 0 0192.004 29.74zM129.306 0a17.392 17.392 0 00-17.392 16.636V41.59s5.044 0 5.044-4.288v-7.668A17.392 17.392 0 10129.306 0zm0 29.74a12.348 12.348 0 1112.348-12.348 12.348 12.348 0 01-12.348 12.348zM166.608 0A17.392 17.392 0 10184 17.392 17.392 17.392 0 00166.608 0zm0 29.74a12.348 12.348 0 1112.356-12.35 12.348 12.348 0 01-12.356 12.35zM56.963 6.299a8.2 8.2 0 015.8 6.806h1.762a9.074 9.074 0 00-7.562-6.806z"
      ></path>
      <path
        fill="#0a8543"
        d="M54.694 0a17.392 17.392 0 1015.668 24.954h-5.913a12.341 12.341 0 01-21.846-5.044h29.3A17.392 17.392 0 0054.694 0zm-.65 4.908a1.664 1.664 0 01-.6-1.24 1.263 1.263 0 112.518 0 1.664 1.664 0 01-.6 1.24 12.242 12.242 0 0111.433 9.46h-24.2a12.258 12.258 0 0111.456-9.46zm9.427 10.722H45.923c-1.059 0-3.327-.507-3.327-1.013h24.2c-.003.506-1.515 1.013-3.325 1.013z"
      ></path>
    </svg>
  )
}

export default Icon
