import React from 'react';

function Icon() {
  return (
    <svg
  xmlns = 'http://www.w3.org/2000/svg'
  width = '117'
  height = '128'
  viewBox = '0 0 117 128' > <g transform = 'translate(-1746 -2477)'>< path
  fill = '#fff'
  d = 'M0 0H117V128H0z'
  transform = 'translate(1746 2477)' >
      </path>
        <g fill="#00946e">
          <path
            d="M129.893 0H87.927a.78.78 0 00-.78.78v6.691h-2.236a2.564 2.564 0 00-2.561 2.561v1.234a2.564 2.564 0 002.561 2.561h2.236v5.549h-2.236a2.564 2.564 0 00-2.561 2.561v1.233a2.564 2.564 0 002.561 2.561h2.236v5.552h-2.236a2.564 2.564 0 00-2.561 2.561v1.234a2.564 2.564 0 002.561 2.561h2.236v5.551h-2.236a2.564 2.564 0 00-2.561 2.561v1.233a2.564 2.564 0 002.561 2.561h2.236v6.689a.78.78 0 00.78.78h41.966a.78.78 0 00.78-.78V.78a.78.78 0 00-.78-.78zM84.911 12.269a1 1 0 01-1-1v-1.237a1 1 0 011-1h2.236v3.238zm0 11.907a1 1 0 01-1-1V21.94a1 1 0 011-1h2.236v3.238zm0 11.906a1 1 0 01-1-1v-1.237a1 1 0 011-1h2.236v3.238zm0 11.907a1 1 0 01-1-1v-1.236a1 1 0 011-1h2.236v3.238zm44.2 7.47H88.706v-53.9h40.407z"
            transform="translate(1697.65 2512)"
          ></path><
      path
  d = 'M92.381 37.63h33.057a.78.78 0 00.78-.78v-3.425a.78.78 0 00-.78-.78h-2.01v-.6a14.544 14.544 0 00-10.2-13.866c0-.084.015-.168.015-.251a4.33 4.33 0 10-8.659 0c0 .083.01.167.015.251a14.545 14.545 0 00-10.2 13.866v.6h-2.01a.78.78 0 00-.78.78v3.425a.78.78 0 00.78.78zm16.532-22.467a2.772 2.772 0 012.762 2.634 14.439 14.439 0 00-5.526 0 2.771 2.771 0 012.764-2.634zM95.953 32.05a12.96 12.96 0 0125.92 0v.6h-25.92zm-2.789 2.156h31.5v1.866h-31.5z'
            transform='translate(1697.65 2512)'
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
