import React from 'react';

function Icon() {
  return (
    <svg
  xmlns = 'http://www.w3.org/2000/svg'
  width = '117'
  height = '128'
  viewBox = '0 0 117 128' > <g transform = 'translate(-1746 -2802)'>< path
  fill = '#fcfcfc'
  d = 'M0 0H117V128H0z'
  transform = 'translate(1746 2802)' >
      </path>
        <path
          fill="#00946e"
          d="M56.727 0H46.712a.954.954 0 100 1.908h10.015a4.41 4.41 0 014.4 4.4v33.784a4.41 4.41 0 01-4.4 4.4H6.312a4.41 4.41 0 01-4.4-4.4V6.312a4.41 4.41 0 014.4-4.4H43.12a.965.965 0 100-1.908H6.312A6.32 6.32 0 000 6.312v33.784a6.319 6.319 0 006.312 6.312h50.415a6.319 6.319 0 006.312-6.312V6.312A6.319 6.319 0 0056.727 0z"
          transform="translate(1773.481 2740.225) translate(0 102.367)"
        ></path><
      path
  fill = '#00946e'
  d = 'M8.136-.004a8.136 8.136 0 108.136 8.136A8.145 8.145 0 008.136-.004zm0 14.364a6.228 6.228 0 116.228-6.228 6.235 6.235 0 01-6.228 6.232z'
          transform='translate(1773.481 2740.225) translate(23.708 108.146)'
        ></path>
        <path
          fill="#00946e"
          d="M24.069 0H9.533a9.486 9.486 0 00-5.33 1.629.954.954 0 101.068 1.581 7.585 7.585 0 014.263-1.3H24.07a7.634 7.634 0 017.625 7.625l-.008 4.64h-4.878V9.709a.954.954 0 00-1.908 0v4.463H8.652V9.711a.954.954 0 10-1.908 0v4.46H1.908V9.539a7.541 7.541 0 01.821-3.447.954.954 0 10-1.7-.864A9.432 9.432 0 000 9.539v4.632a1.918 1.918 0 001.916 1.916h29.77a1.918 1.918 0 001.916-1.916V9.539A9.544 9.544 0 0024.069 0z"
          transform="translate(1773.481 2740.225) translate(14.719 126.223)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
