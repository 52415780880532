import React from 'react'

function Icon() {
  return (
    <svg
  xmlns = 'http://www.w3.org/2000/svg'
  width = '117'
  height = '128'
  viewBox = '0 0 117 128' > <g transform = 'translate(-1746 -2962)'>< rect
  width = '117'
  height = '128'
  fill = '#fff'
  rx = '10'
  transform = 'translate(1746 2962)' >
      </rect>
        <path
          fill="#00946e"
          d="M10.927 5.445l-.669-2.2a4.783 4.783 0 00-9.057 0l-.669 2.2a12.367 12.367 0 00-.345 5.731 5.39 5.39 0 002.776 3.756L2.214 31.68a3.3 3.3 0 00.834 2.565 3.64 3.64 0 005.142.23q.12-.11.23-.23a3.255 3.255 0 00.834-2.5l-.753-16.82a5.389 5.389 0 002.772-3.754 12.367 12.367 0 00-.346-5.726zm-1.708 5.368a3.3 3.3 0 01-2.162 2.489 1.042 1.042 0 00-.674 1.022l.792 17.59a1.205 1.205 0 01-.313.944 1.61 1.61 0 01-2.265 0 1.251 1.251 0 01-.306-1.013l.786-17.521a1.042 1.042 0 00-.674-1.022 3.3 3.3 0 01-2.162-2.489 10.276 10.276 0 01.287-4.758l.669-2.2a2.7 2.7 0 015.068 0l.669 2.2a10.29 10.29 0 01.285 4.76z"
          transform="translate(1774 2994.535) translate(33.345 15.624)"
        ></path><
      path
  fill = '#00946e'
  d = 'M10.42 0a1.042 1.042 0 00-1.042 1.042v8.947L6.557 12.81a1.042 1.042 0 00-.305.789l.912 18.235a1.419 1.419 0 01-.394 1.062 1.467 1.467 0 01-2.075.009l-.009-.009a1.419 1.419 0 01-.394-1.062l.918-18.235a1.042 1.042 0 00-.3-.789L2.088 9.989V1.042a1.042 1.042 0 00-2.084 0v9.378a1.042 1.042 0 00.305.737l2.8 2.8-.889 17.775a3.52 3.52 0 007.031.343q.008-.171 0-.342l-.889-17.775 2.8-2.8a1.042 1.042 0 00.305-.737V1.043A1.042 1.042 0 0010.42 0z'
  transform = 'translate(1774 2994.535) translate(17.714 15.63)' >
      </path>
        <path
          fill="#00946e"
          d="M1.042 0A1.042 1.042 0 000 1.042v8.336a1.042 1.042 0 102.084 0V1.042A1.042 1.042 0 001.042 0z"
          transform="translate(1774 2994.535) translate(20.84 15.63)"
        ></path><
      path
  fill = '#00946e'
  d = 'M1.042 0A1.042 1.042 0 000 1.042v8.336a1.042 1.042 0 102.084 0V1.042A1.042 1.042 0 001.042 0z'
  transform = 'translate(1774 2994.535) translate(23.966 15.63)' >
      </path>
        <path
          fill="#00946e"
          d="M31.26 0a31.26 31.26 0 1031.26 31.26A31.26 31.26 0 0031.26 0zm0 60.435A29.176 29.176 0 1160.435 31.26 29.176 29.176 0 0131.26 60.435z"
          transform="translate(1774 2994.535)"
        ></path><
      path
  fill = '#00946e'
  d = 'M3.005.372A1.042 1.042 0 001.537.243a1.042 1.042 0 00-.125 1.468A18.756 18.756 0 01.306 27.08a1.042 1.042 0 001.471 1.476A20.84 20.84 0 003.005.372z'
          transform='translate(1774 2994.535) translate(44.193 17.46)'
        ></path>
        <path
          fill="#00946e"
          d="M7.555 25.934A18.754 18.754 0 015.671 1.668 1.042 1.042 0 104.005.416l-.018.025a20.84 20.84 0 002.091 26.971 1.044 1.044 0 001.476-1.475z"
          transform="translate(1774 2994.535) translate(10.418 18.559)"
        ></path>
      </g>
    </svg>
  )
}

export default Icon
