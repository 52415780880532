/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = (props) => (
  <svg
    width="250"
    height="91.59"
    viewBox="0 0 308.252 46.642"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-184 241.59)">
      <path
        fill="#fff"
        d="M201.392-241.59A17.392 17.392 0 00184-224.954V-200s5.044 0 5.044-4.288v-7.668a17.392 17.392 0 1012.348-29.634zm0 29.74a12.348 12.348 0 1112.348-12.348 12.348 12.348 0 01-12.348 12.348zM276.004-241.59a17.392 17.392 0 00-17.392 16.636V-200s5.036 0 5.036-4.288v-7.668a17.392 17.392 0 1012.356-29.634zm0 29.74a12.348 12.348 0 1112.348-12.348 12.348 12.348 0 01-12.348 12.348zM313.306-241.59a17.392 17.392 0 00-17.392 16.636V-200s5.044 0 5.044-4.288v-7.668a17.392 17.392 0 1012.348-29.634zm0 29.74a12.348 12.348 0 1112.348-12.348 12.348 12.348 0 01-12.348 12.348zM350.608-241.59A17.392 17.392 0 10368-224.198a17.392 17.392 0 00-17.392-17.392zm0 29.74a12.348 12.348 0 1112.356-12.35 12.348 12.348 0 01-12.356 12.35zM240.963-235.291a8.2 8.2 0 015.8 6.806h1.762a9.074 9.074 0 00-7.562-6.806z"
      ></path>
      <path
        fill="#fff"
        d="M238.694-241.59a17.392 17.392 0 1015.668 24.954h-5.913a12.341 12.341 0 01-21.846-5.044h29.3a17.392 17.392 0 00-17.209-19.91zm-.65 4.908a1.664 1.664 0 01-.6-1.24 1.263 1.263 0 112.518 0 1.664 1.664 0 01-.6 1.24 12.242 12.242 0 0111.433 9.46h-24.2a12.258 12.258 0 0111.456-9.46zm9.427 10.722h-17.548c-1.059 0-3.327-.507-3.327-1.013h24.2c-.003.506-1.515 1.013-3.325 1.013z"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        d="M0 0L0 42"
        transform="translate(380.5 -241.5)"
      ></path>
      <path
        fill="#fff"
        d="M413.592-207h5.244v-31.97h-5.244v13.708H398.55v-13.708h-5.244V-207h5.244v-13.984h15.042zm22.816-21.344c3.91 0 6.992 2.484 7.084 6.394h-14.03c.552-4.002 3.404-6.394 6.946-6.394zm11.868 13.8h-5.658a6.136 6.136 0 01-5.98 3.544 6.972 6.972 0 01-7.222-6.716h19.366a16.177 16.177 0 00.184-2.576c0-7.452-5.106-12.466-12.328-12.466-7.5 0-12.65 5.106-12.65 13.064s5.382 13.108 12.65 13.108c6.21 0 10.212-3.542 11.638-7.958zm5.842 7.544h5.244v-34.04h-5.244zm17.388-21.62v-3.726h-5.244v37.4h5.244v-15.734a11.459 11.459 0 008.832 4.094c6.67 0 11.914-5.382 11.914-13.2s-5.244-12.974-11.914-12.974a11.059 11.059 0 00-8.832 4.14zm15.41 8.832c0 5.474-3.772 8.648-7.728 8.648-3.91 0-7.682-3.082-7.682-8.556 0-5.428 3.772-8.51 7.682-8.51 3.956 0 7.728 2.944 7.728 8.418z"
      ></path>
    </g>
  </svg>
)

Logo.defaultProps = {
  color: "white",
}

export default Logo
