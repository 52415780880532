import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g fill="none" transform="translate(-1564 -2893)">
        <path
          stroke="#00946e"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1593.877 2902.33v-6.421h-24.2v29.591c0 5.033 8 5.026 8 0v-4.28h20.481v4.28a3.919 3.919 0 01-3.648 3.648h-14.033"
        ></path>
        <path
          stroke="#00946e"
          strokeLinecap="round"
          d="M1595.193 2904.438v15M1591.746 2910.007h-15.256M1591.746 2914.685h-15.256"
        ></path>
        <path d="M0 0H40V40H0z" transform="translate(1564 2893)"></path>
      </g>
    </svg>
  );
}

export default Icon;
