import React from 'react';

function Icon() {
  return (
    <svg
  xmlns = 'http://www.w3.org/2000/svg'
  width = '117'
  height = '128'
  viewBox = '0 0 117 128' > <g transform = 'translate(-1746 -2640)'>< path
  fill = '#fff'
  d = 'M0 0H117V128H0z'
  transform = 'translate(1746 2640)' >
      </path>
        <path
          fill="#00946e"
          d="M58.8 48.248h-1.027V21.464a8.38 8.38 0 003.86-7.051.9.9 0 00-.119-.448L54.35 1.427A2.839 2.839 0 0051.89 0H9.743a2.839 2.839 0 00-2.46 1.427L.119 13.964a.9.9 0 00-.119.448 8.38 8.38 0 003.86 7.052v26.784H2.833a.903.903 0 000 1.806H58.8a.903.903 0 100-1.806zM1.868 15.315h2.894a.903.903 0 100-1.806h-2.3L8.851 2.323a1.029 1.029 0 01.892-.518h42.148a1.029 1.029 0 01.892.518l6.392 11.186h-2.3a.903.903 0 000 1.806h2.895a6.575 6.575 0 01-13.025 0h6.271a.903.903 0 000-1.806H4.762a.903.903 0 100 1.806h10.131a6.575 6.575 0 01-13.025 0zm42.939 0a6.574 6.574 0 01-13.024 0zm-14.957 0a6.574 6.574 0 01-13.024 0zm21.293 32.933h-9.774v-3.019h9.774zm0-4.825h-9.774V26.895h9.774zm4.825 4.825h-3.019V25.992a.9.9 0 00-.9-.9H40.466a.9.9 0 00-.9.9v22.256H5.665V22.339a8.373 8.373 0 0010.195-4.145 8.38 8.38 0 0014.957 0 8.38 8.38 0 0014.957 0 8.373 8.373 0 0010.194 4.145z"
          transform="translate(1773.481 2628.167) translate(0 48.096)"
        ></path>< path
  fill = '#00946e'
  d = 'M26.955 0H.903a.9.9 0 00-.9.9v18.334a.9.9 0 00.9.9h26.052a.9.9 0 00.9-.9V.903a.9.9 0 00-.9-.903zm-.9 18.334H1.806V1.806h24.249z'
  transform = 'translate(1773.481 2628.167) translate(8.685 73.185)' >
      </path>
      </g>< /svg>
  );
}

export default Icon;