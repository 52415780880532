import React from 'react';

function Icon() {
  return (
    <svg
  xmlns = 'http://www.w3.org/2000/svg'
  width = '117'
  height = '128'
  viewBox = '0 0 117 128' > <g transform = 'translate(-1746 -3125)'>< rect
  width = '117'
  height = '128'
  fill = '#fff'
  rx = '10'
  transform = 'translate(1746 3125)' >
      </rect>
        <path
          fill="#00946e"
          d="M51.894 13.912a30.172 30.172 0 00-19.352-8.829l3.7-3.7L34.852 0l-6.185 6.185 6.185 6.185 1.387-1.387-3.95-3.949A28.437 28.437 0 111.961 35.407c0-.631.021-1.268.062-1.9l-1.957-.129C.022 34.047 0 34.728 0 35.402a30.4 30.4 0 1051.894-21.5z"
          transform="translate(1754.637 3156.098) translate(19.482)"
        ></path><
      path
  fill = '#00946e'
  d = 'M1.089 0C.708.556.342 1.13 0 1.708l1.688 1c.32-.541.663-1.078 1.019-1.6z'
  transform = 'translate(1754.637 3156.098) translate(23.7 18.24)' >
      </path>
        <path
          fill="#00946e"
          d="M1.306 0C.856.5.416 1.021 0 1.549l1.541 1.214c.39-.494.8-.982 1.222-1.449z"
          transform="translate(1754.637 3156.098) translate(25.99 15.059)"
        ></path><
      path
  fill = '#00946e'
  d = 'M.334 0A30.545 30.545 0 000 2l1.944.258a28.7 28.7 0 01.312-1.87z'
  transform = 'translate(1754.637 3156.098) translate(19.748 29.367)' >
      </path>
        <path
          fill="#00946e"
          d="M1.499 0C.987.435.483.894-.001 1.362l1.367 1.407c.452-.439.923-.868 1.4-1.275z"
          transform="translate(1754.637 3156.098) translate(28.701 12.238)"
        ></path><
      path
  fill = '#00946e'
  d = 'M.852 0C.552.6.262 1.22 0 1.839l1.806.765c.245-.579.513-1.157.8-1.72z'
  transform = 'translate(1754.637 3156.098) translate(21.875 21.726)' >
      </path>
        <path
          fill="#00946e"
          d="M.599 0a30.77 30.77 0 00-.6 1.937l1.892.516a28.69 28.69 0 01.56-1.811z"
          transform="translate(1754.637 3156.098) translate(20.549 25.457)"
        ></path><
      path
  fill = '#00946e'
  d = 'M21.505 0a21.5 21.5 0 1021.5 21.5A21.529 21.529 0 0021.505 0zm0 41.048a19.544 19.544 0 1119.544-19.544 19.566 19.566 0 01-19.544 19.544z'
          transform='translate(1754.637 3156.098) translate(28.513 13.833)'
        ></path>
        <path
          fill="#00946e"
          d="M1.961 10.838V0H0v11.65l6.105 6.105 1.387-1.387z"
          transform="translate(1754.637 3156.098) translate(48.9 23.705)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
